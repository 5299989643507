import * as translations from '../translations';
import { addwishTracker } from '@jetshop/flight-addwish';
import channelsQuery from './ChannelsQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage
} from './components/CategoryPage/CategoryPage';
import ProductPage from './components/ProductPage/ProductPage.loadable';
import ContentPage from './components/ContentPage/ContentPage.loadable';
import { theme } from './components/Theme';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';

export const useWPExstension = true;

const config = {
  theme,
  apolloConfig: {
    useGraphQLProxyServer: useWPExstension,
    shopid: process.env.REACT_APP_SHOP_ID || 'hektapatur',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.se',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: false,
  sentry: {
    clientDSN: process.env.FLIGHT_SENTRY_CLIENT_DSN,
    serverDSN: process.env.FLIGHT_SENTRY_SERVER_DSN,
    ignoreErrors: []
  },
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          NKR: {
            style: 'currency',
            currency: 'NKR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        }
      }
    }
  },
  schemaExtensions: [],
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [addwishTracker('EA7EC32E1A5F97080F7A53C8F3BBB08A')],
  serverTrackers: [
    createFacebookTracker({
      pixelID: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN
    })
  ],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [ContentPage]
    }
  },
  brandCustomField: 'Brand'
};

export default config;
